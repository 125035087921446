.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blink-on-change {
  animation-name: blink;
  animation-duration: 1s;
}

@keyframes blink {
  0%   {background-color: #9addfc; }
  25%  {background-color: rgba(154, 221, 252, 0.75);}
  50%  {background-color: rgba(154, 221, 252, 0.5);}
  75%  {background-color: rgba(154, 221, 252, 0.25);}
  100% {background-color: rgba(87, 207, 255, 0);}
}

html, body {
  max-width: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.outer-ring {
  height: 128px;
  width: 128px;
  border-radius: 100%;
}

.inner-ring {
  position: relative;
  top: 3px;
  height: 122px;
  width: 122px;
  background-color: white;
  border-radius: 100%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.input-group-text-prepend-30 {
  flex: 0 0 30%;
}

.input-group-text-prepend-40 {
  flex: 0 0 40%;
}

.input-group-text-prepend-45 {
  flex: 0 0 40%;
}

.input-group-text-prepend-50 {
  flex: 0 0 50%;
}

.input-group-text-prepend-55 {
  flex: 0 0 55%;
}

.input-group-text-prepend-60 {
  flex: 0 0 60%;
}

.input-group-text-prepend-70 {
  flex: 0 0 70%;
}

.input-group-text-prepend-80 {
  flex: 0 0 80%;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('./resources/fonts/Poppins/Poppins-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Tungsten';
  src: local('Tungsten'), url('./resources/fonts/Tungsten/Tungsten-Bold.ttf') format('opentype');
}