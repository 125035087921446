.welcome-title {
    font-family: 'Tungsten';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    color: #FC8FE0;
}

.welcome-subtitle {
    font-family: 'Tungsten';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.0038em;

    color: #FFFFFF;
}

.welcome-photo {
    width: 269px;
    height: 269px;
}

.welcome-review {
    width: 267px;
    height: 147px;
}

.button-store {
    width: 142.83px !important;
    height: 47.16px !important;
}