.card-loading-overlay {
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
}
.card-loading-overlay-animation{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}
.circle{
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #321f6b;
    border-radius: 50%;
    animation: loading 1.5s cubic-bezier(.8, .5, .2, 1.4) infinite;
    transform-origin: bottom center;
    position: relative;
}
@keyframes loading{
    0%{
        transform: translateY(0px);
        background-color: #4c2ad9;
    }
    50%{
        transform: translateY(50px);
        background-color: #651b93;
    }
    100%{
        transform: translateY(0px);
        background-color: #e1b4ff;
    }
}
.circle-1{
    animation-delay: 0.1s;
}
.circle-2{
    animation-delay: 0.2s;
}
.circle-3{
    animation-delay: 0.3s;
}
.circle-4{
    animation-delay: 0.4s;
}
.circle-5{
    animation-delay: 0.5s;
}
.circle-6{
    animation-delay: 0.6s;
}
.circle-7{
    animation-delay: 0.7s;
}
.circle-8{
    animation-delay: 0.8s;
}


.lds-ripple {
    display: inline-block;
    width: 128px;
    height: 128px;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #4c2ad9;
    opacity: 1;
    border-radius: 100%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 60px;
        left: 60px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0;
        left: 0;
        width: 124px;
        height: 124px;
        opacity: 0;
    }
}
