.deposit-background {
    background: linear-gradient(100.04deg, #1E90E5 0.72%, #102CB3 101.83%);
}

.deposit-col {
    margin-top: 36px;
    max-width: 312px;
}

.deposit-header {
    width: 317.57px !important;
    height: 113px;
}

.deposit-avatar {
    border: 3px solid #FC8FE0;
    border-radius: 50%;
    width: 66px;
    height: 66px;
}

.deposit-avatar-star {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 17px;
    height: 17px;
    margin-left: 26px;
    margin-top: 18px;
}

.deposit-avatar-text {
    font-family: 'Tungsten';
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 67px;
    text-align: center;
    position: relative;
    /* identical to box height */
    text-align: center;

    color: #FC8FE0;
}

.deposit-info-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    color: #FFFFFF;
}

.deposit-info-currency {
    position: absolute;
    left: 0%;
    top: 4%;
    width: 64px;
    height: 94px;
    margin-left: 18px;
}

.deposit-input-amount {
    position: relative;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    font-size-adjust: initial;
    text-align: center;

    margin-bottom: 24px;

    color: #FFFFFF;
    background: transparent;
    width: 150px;
    height: 100px;
    border: transparent;
}

.deposit-input-amount textarea:focus, input:focus{
    outline: none;
}

.deposit-input-amount-line {
    position: absolute;
    bottom: 28px;
    width: 150px;
    height: 1px;
    background: white;
}

.deposit-input-title-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #FFFFFF;
}

.deposit-input-message-label {
    margin-top: 28px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #FFFFFF;
}

.deposit-input {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 24px;
}

.deposit-input::-webkit-input-placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 24px;
}

.deposit-input:-moz-placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 24px;
}

.deposit-input::-moz-placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 24px;
}

.deposit-button-send {
    height: 49px;

    background: #27C5FC;
    border-radius: 37px;
    margin-bottom: 24px;
    border: #000000;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;

    /* identical to box height */

    color: #FFFFFF;
}

.deposit-button-send:disabled {
    height: 49px;

    background: rgba(39, 197, 252, 0.5);
    border-radius: 37px;
    margin-bottom: 24px;
    border: #000000;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;

    /* identical to box height */

    color: #FFFFFF;
}


.deposit-error {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    /* identical to box height */

    color: #79282C;
}

.qr-code-share-div {
    position: absolute;
    width: 24px !important;
    max-width: 24px !important;
    max-height: 24px !important;
    height: 24px !important;
    padding: 0;
}

.qr-code-share-icon {
    padding: 0;
    width: 100%;
    height: 100%;
}

.qr-code-scan-text {
    font-family: 'Tungsten';
    font-style: normal;
    font-weight: 400;
    font-size: 54px;
    line-height: 120px;

    /* identical to box height */
    text-align: center;
    letter-spacing: 0.0038em;
    text-decoration-line: underline;

    color: #330075;
}

.qr-code-app-logo{
    width: 24px !important;
    height: 24px !important;
}